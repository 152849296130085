import React from "react";

import '../../styles/contacto.css'
import { Footer } from "../Footer/Footer";
import { Separator } from "../Headers/Separator";
import { ContactoLinks } from "./ContactosLinks";


export const Contacto = () =>{
    return(
        <section id="Contacto" className="section-2">
            <Separator name='Contacto'></Separator>
            <div className="container contacto-container d-flex">
                <div className="row w-100">
                    <div className="col w-100">
                        <div className="row h-100 d-flex flex-column">
                            <div className="col">
                                <h6 className="header-ubicacion">Ubícanos</h6>
                                <p className="text-ubicacion header-place">Centro de Innovación, Investigación y Desarrollo en Ingeniería y Tecnología: </p>
                                <p className="text-ubicacion">PIIT Monterrey, Km. 10, Autopista al Aeropuerto Internacional de Monterrey, Apodaca, Nuevo León, México.</p>
                            </div>
                            <div className="col">
                                <p className="text-ubicacion header-place">Centro de Inovación en Ingeniería de Tenología Inteligente Biomédica y Mecatrónica:</p>
                                <p className="text-ubicacion">Pedro de Alba SN, Niños Héroes, Ciudad Universitaria, San Nicolás de los Garza, N.L.  </p>
                            </div>

                        </div>
                    </div>
                    <div className="col w-100">
                    <h6 className="header-ubicacion">Envía un correo</h6>
                        <form target="_blank" action="https://formsubmit.co/raul.lucioprt@uanl.edu.mx" method="POST">
                            <div className="form-floating mb-3">
                                <input type="email" name="email" className="form-control " id="floatingInput" placeholder="name@example.com"/>
                                <label htmlFor="floatingInput">Email address</label>
                            </div>
                            <div className="form-floating">
                                <input type="text" name="Asunto" className="form-control" id="floatingInput2" placeholder="Subject"/>
                                <label htmlFor="floatingInput">Subject</label>
                            </div>
                            <br />
                            <div className="form-floating ">
                                <textarea name="Mensaje" className="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style={{height: "100px"}}></textarea>
                                <label htmlFor="floatingTextarea2">Comments</label>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <button type="submit" className="btn btn-dark w-50">Enviar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
            <Separator name='Síguenos'></Separator>
            <div className="col w-50 container">
                <ContactoLinks></ContactoLinks>
            </div>
            <div>&&nbsp;</div>
           

            <Footer></Footer>

        </section>
    )
}
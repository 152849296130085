export const PUBLIS = [
    { 
        date : "2022", 
        datos: [ {
            "tittle": "Julia M Loredo Alejos, Raúl Lucio Porto, Luis López Pavón, Iván E. Moreno Cortez. Pepsin Immobilization by Electrospinning of Poly(Vinyl Alcohol) Nanofibers. Journal of Applied Polymer Science, 139, 51700, 2022.",
            "url": "https://onlinelibrary.wiley.com/doi/abs/10.1002/app.51700"
            }
            ],
    },
    {
        date: "2021",
        datos: [
            {
                "tittle": "Jorge Alexis Zuñiga Martínez, Sara Elena González Náñez, Etienne LeCalvez, Raúl Lucio Porto, Iván Eleazar Moreno Cortez, Thierry Brousse, Luis Alberto López. Layered Vanadium Phosphates as Electrodes for Electorchemical Capacitors Part II: The Case of VOPO4·CTAB and K0.5VOPO4·1.5H2O. Journal of the Electrochemical Society. 168, 090520, 2021.",
                "url": "https://iopscience.iop.org/article/10.1149/1945-7111/ac23a0"
            },
            {
                "tittle":"Jorge Alexis Zuñiga Martínez, Sara Elena González Náñez, Etienne LeCalvez, Raúl Lucio Porto, Iván Eleazar Moreno Cortez, Thierry Brousse, Luis Alberto López. Layered Vanadium Phosphates as  Electrodes for Electorchemical Capacitors Part I: The Case of VOPO4·2H2O. Journal of the Electrochemical Society. 168, 070531, 2021.",
                "url": "https://iopscience.iop.org/article/10.1149/1945-7111/ac11a3/meta"

            }
            
        ]
        ,url: 'https://onlinelibrary.wiley.com/doi/abs/10.1002/app.51700' 

    },
    {
        date: "2020",
        datos: [
                {
                    "tittle": "Adela Sánchez Oseguera, Arnulfo López Meléndez, Raúl Lucio Porto, Eder Ubaldo Arredondo Espinoza, Omar González Santiago, Mónica Azucena Ramírez Cabrera. Anticancer Activity of VOHPO4·2H2O Nanoparticles in Vitro. Journal of Drug Delivery Science and Technology, 60, 102032, 2020.",
                    "url": "https://www.sciencedirect.com/science/article/abs/pii/S1773224720313216"
                }
                ]
        ,url: 'https://onlinelibrary.wiley.com/doi/abs/10.1002/app.51700' 

    },
    {
        date: "2018",
        datos: [
            {
                "tittle":"Jorge Alexis Zúñiga Martínez, Raúl Lucio Porto, Iván Eleazar Moreno Cortez, Thierry Brousse, Josué Amilcar Aguilar Martínez, Luis Alberto López Pavón. MnPO4·H2O as Electrode Material for Electrochemical Capacitors. Journal of The Electrochemical Society, 165, A2349-A2356, 2018.",
                "url": "https://iopscience.iop.org/article/10.1149/2.1281810jes/meta"
            },

            {
                "tittle": "Amine Achour, Raul Lucio-Porto, Shahram Solaymani, Mohammad Islam, Iftikhar Ahmad, Thierry Brousse. Reactive Sputtering of Vanadium Nitride thin Films as Pseudo-capacitor Electrodes for High Areal Capacitance and Cyclic Stability. Journal of Materials Science: Materials in Electronics. 29, 13125-13131, 2018.",
                "url": "https://www.springerprofessional.de/en/reactive-sputtering-of-vanadium-nitride-thin-films-as-pseudo-cap/15830112"                
            },
            {
                "tittle": "Nadir Ouldhamadouche, Amine Achour, Raul Lucio-Porto, Mohammad Islam, Shahram Solaymani, Ali Arman, Azin Ahmadpourian, Hamed Achour, Laurent Le Brizoual, Mohamed Abdou Djouadi, Thierry Brousse. Electrodes Based on Nano-tree-like Vanadium Nitride and Carbon Nanotubes for Microsupercapacitors. Journal of Materials Science & Technology, 34, 976-982, 2018.",
                "url": "https://www.sciencedirect.com/science/article/abs/pii/S1005030217303201"                
            },
            {
                "tittle": "Andrea S Rojas-Mercado, Iván E Moreno-Cortez, Raúl Lucio-Porto, Luis López Pavón. Encapsulation and Immobilization of Ficin Extract in Electrospun Polymeric Nanofibers. International Journal of Biological Macromolecules, 118, 2287-2295, 2018.",
                "url": "https://pubmed.ncbi.nlm.nih.gov/30031077/"                
            },
        ]

    },
    {
        date: "2017",
        datos: [
            {
                "tittle": " A Achour, Raúl Lucio-Porto, M Chaker, A Arman, A Ahmadpourian, MA Soussou, M Boujtita, L Le Brizoual, MA Djouadi, T Brousse. Titanium Vanadium Nitride Electrode for Micro-supercapacitors. Electrochemistry Communications, 77, 40-43, 2017.",
                "url": "https://www.sciencedirect.com/science/article/pii/S1388248117300437"
            },
            {
                "tittle": "Sofía E Rodríguez‐de Luna, Iván E Moreno Cortez, MA Garza‐Navarro, Raúl Lucio Porto, Luis López Pavón, Virgilio A González‐González. Thermal stability of the Immobilization Process of Horseradish Peroxidase in Electrospun Polymeric Nanofibers. Journal of Applied Polymer Science, 134, 19, 44811, 2017.",
                "url": "https://onlinelibrary.wiley.com/doi/full/10.1002/app.44811"
            }   
         ]
    },
    {
        date: "2016",
        datos: [
            {
                "tittle": "Alban Morel, Yann Borjon-Piron, Raúl Lucio Porto, Thierry Brousse, Daniel Bélanger. Suitable Conditions for the Use of Vanadium Nitride as an Electrode for Electrochemical Capacitor. Journal of The Electrochemical Society. 163, A1077-A1082, 2016.",
                "url": "https://iopscience.iop.org/article/10.1149/2.1221606jes"   
            },
            {
                "tittle": "MA Martínez-Rodríguez, MA Garza-Navarro, IE Moreno-Cortez, Raúl Lucio-PortCarbohydrate polymero, VA González González. Silver/Polysaccharide-based Nanofibrous Materials Synthesized from Green Chemistry Approach. s, 136, 46-53, 2016.",
                "url": "https://pubmed.ncbi.nlm.nih.gov/26572327/"

            },
            {
                "tittle": "A Estrada de la Vega, MA Garza-Navarro, JG Durán-Guerrero, IE Moreno Cortez, Raúl Lucio Porto, V González-González. Tailoring the Magnetic Properties of Cobalt-ferrite Nanoclusters. Journal of Nanoparticle Research, 18, 18, 2016.",
                "url": "https://link.springer.com/article/10.1007/s11051-016-3325-1"
            },                
            
                ]

    },   
    {
        date: "2015",
        datos:
        [
            {
                "tittle": "Amine Achour, Raul Lucio Porto, Mohamed-Akram Soussou, Mohammad Islam, Mohammed Boujtita, Kaltouma Ait Aissa, Laurent Le Brizoual, Abdou Djouadi, Thierry Brousse. Titanium nitride films for micro-supercapacitors: Effect of Surface Chemistry and Film Morphology on the Capacitance. Journal of Power Sources, 300, 525-532, 2015.",
                "url": "https://www.sciencedirect.com/science/article/abs/pii/S0378775315302731"
            }
        ]

    },

    {
        date: "2014",
        datos: [
            {
                "tittle": "A Achour, JB Ducros, R Lucio Porto, M Boujtita, E Gautron, L Le Brizoual, MA Djouadi, T Brousse. Hierarchical Nanocomposite Electrodes Based on Titanium Nitride and Carbon Nanotubes for Microsupercapacitors. Nano Energy, 7, 104-113, 2014.",
                "url": "https://www.sciencedirect.com/science/article/abs/pii/S0378775315302731"
            }
        ]
    },
    {
        date: "2013",
        datos:[
            {
                "tittle": "Etienne Eustache, Renaud Frappier, Raúl Lucio Porto, Saïd Bouhtiyya, Jean-François Pierson, Thierry Brousse. Asymmetric Electrochemical Capacitor Microdevice Designed with Vanadium Nitride and Nickel Oxide thin Film Electrodes. Electrochemistry Communications, 28, 104-106, 2013.",
                "url": "https://www.sciencedirect.com/science/article/pii/S1388248112005449"

            },
            {
                "tittle": "S Bouhtiyya, R Lucio Porto, B Laïk, P Boulet, F Capon, JP Pereira-Ramos, Thierry Brousse, JF Pierson. Application of Sputtered Ruthenium Nitride thin Films as Electrode Material for Energy-storage Devices. Scripta Materialia, 68, 659-662, 2013.",
                "url": "https://www.sciencedirect.com/science/article/abs/pii/S1359646213000523"

            },
            {
                "tittle": "Nubia E Torres-Martínez, MA Garza-Navarro, Raúl Lucio-Porto, Domingo García-Gutiérrez, Alejandro Torres-Castro, Virgilio A González-González. One-pot Synthesis of Magnetic Hybrid Materials Based on Ovoid-like Carboxymethyl-cellulose/Cetyltrimethylammonium-bromide Templates. Materials Chemistryand Physics, 141, 735-743, 2013.",
                "url": "https://www.sciencedirect.com/science/article/abs/pii/S0254058413004537"
            }
        ]

    },
    {
        date: "2012",
        datos: [
            {
                "tittle": "R Lucio-Porto, I Gómez. Synthesis of Manganese Oxide Nanocompounds for Electrodes in Electrochemical Capacitors. 42, 833-838, 2012.",
                "url": "https://www.tandfonline.com/doi/abs/10.1080/15533174.2011.618477"

            }
        ]

    },
    {
        date: "2009",
        datos: [
            {
                "tittle": "Raúl Lucio Porto, Luis Carlos Torres González. Síntesis de Óxido de Manganeso Nanoestructurado para Capacitores Electroquímicos. Ingenierías, 12, 42-49, 2009.",
                "url": "http://eprints.uanl.mx/10412/",
                },
        ]

    },
    ]

import React from "react";


export const CardEventosFinales = (props) =>{
    return(              
        <div className="card d-flex align-items-center justify-content-center card-evento" style={{width: '18rem'}}>
        <div className="imge-card-container d-flex align-items-center justify-content-center">
            <div className="img-content">
                <img id={'id-img-evenf-' + props.idx} loading="lazy" className= "imagen-evento foto-evento card-img-top" src={require('../../assets/fotos/eventos/' + props.foto)} alt="evento" />
            </div>
            
        </div>
        <div className="card-body bg-blue d-flex align-items-center justify-content-cente flex-column">
            <h5 className="card-title name-evenf text-center">{props.name} </h5>
            <p className="card-text linea-evenf">{props.uni}</p>
            <small className="card-text text-center linea-inv-text">{props.linea}</small>
        </div>
    </div>
    ) 
}


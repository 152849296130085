import React from "react";

export const Footer = ()=>{
    return(
        <footer id="sticky-footer" class="flex-shrink-0 py-4  text-white-50">
        <div class="container text-center d-flex flex-column">
          <small>Copyright &copy; rlucioporto.com </small>
          <small>La ciencia, una luz en la oscuridad.</small>

        </div>
      </footer>
    )
}
import Regate from '../../assets/240px-ResearchGate.png'
import ORCID from '../../assets/512px-ORCID.png'
import GoogleAca from '../../assets/GoogleAca.png'
import UANL_LOGO from   '../../assets/UANL.png'
import Youtube from '../../assets/Youtube.png'
import Instagram from '../../assets/Instagram.png'


export const ContactoLinks = () =>{
    return(

        <div className="col d-flex align-iteims">
            <a rel="noreferrer" target='_blank' className="contacto-especial" href="https://orcid.org/0000-0002-6894-9066">
                <img  src={ORCID} alt=""/>
            </a>
            <a rel="noreferrer" target='_blank' className="contacto-especial" href="https://scholar.google.com/citations?user=anEJefMAAAAJ&hl=es">
                <img  src={GoogleAca} alt="" />
            </a>
            <a rel="noreferrer" target='_blank' className="contacto-especial" href="https://www.researchgate.net/profile/Raul-Lucio-Porto">
                <img  src={Regate} alt="" />
            </a>
            <a rel="noreferrer" target='_blank' className="contacto-especial" href="https://www.uanl.mx/investigadores/raul-lucio-porto/">
                <img  src={UANL_LOGO} alt="" />
            </a>
            <a rel="noreferrer" target='_blank' className="contacto-especial" href="https://www.instagram.com/rrsciencemx/?igshid=YmMyMTA2M2Y%3D">
                <img src={Instagram} alt=""/>
            </a>
            <a rel="noreferrer" target='_blank' className="contacto-especial" href="https://www.youtube.com/channel/UCUWKn6rICa63UH2F6Idc4rQ/featured">
                <img src={Youtube} alt=""/>
            </a> 
             
        </div>
    )
}
import React from "react";

import { Separator } from "../Headers/Separator";
import Carousel from 'react-multi-carousel';
import { CardEventosProximos } from "./CardEventosProximos";
import EVENTOS from '../../Json/EVENTOSPROX.json'

import 'react-multi-carousel/lib/styles.css';
import '../../styles/EventosProximos.css'



const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };




export const EventosProximos = ( ) =>{
  
    return(
        <section id="EventosProximos" className="section">
            <Separator name ='Próximos Eventos'/>

            <div className="container">
                <Carousel 
                responsive={responsive}
                showDots={true}
                containerClass="carousel-container"
                > 
                    { 
                      EVENTOS.map( (elem, idx) =>{
                      return(
                          <CardEventosProximos idx={idx} key={elem}  name ={elem.name} foto={elem.foto} uni={elem.descripcion} linea={elem.fecha}></CardEventosProximos>
                        )
                      })}

                    </Carousel>
            </div>


        </section>
    )
}
import React from "react";
import { DotIndex } from "../Index/DotIndex";

import '../../styles/index.css'

const direcciones = [
    {
        name:'Home',
        icon: ' bi-person-circle ',
        dir: '#Home',
        color: 'color-1'
    },
    {
        name: "Proyectos",
        icon: 'bi-folder',
        dir: "#Proyectos",
        color: 'color-2'
    },

    {
        name:'Grupo',
        icon: ' bi-people-fill ',
        dir: '#Estudiantes',
        color: 'color-2'
    },
    {
        name:'Publicaciones',
        icon: ' bi-journals ',
        dir: '#Publicaciones',
        color: 'color-31'
    },
    {
        name:'Investigación ',
        icon: ' bi-search ',
        dir: '#LineasInvestigacion',
        color: 'color-4'
    },
    {
        name:'Eventos',
        icon: ' bi-calendar-event ',
        dir: '#EventosProximos',
        color: 'color-6'
    },
    {
        name:'Blog',
        icon: ' bi-globe-americas ',
        dir: '#Blog',
        color: 'color-5'
    },

    {
        name:'Contacto',
        icon: ' bi-envelope-at ',
        dir: '#Contacto',
        color: 'color-7'
    },
]

export const  Navbar  = () =>{
    return(
        
        <>
        
        <div className="container-fluid" >
            <br />
            <div className="row d-flex  align-items-center justify-content-center ">
                <div id="container-index-media" className="row container-index d-flex column-gap-5 align-items-center justify-content-center"> 
                {
                        direcciones.map( 
                            ( elem ) =>{                                
                            return(
                            <DotIndex key={elem.color} color={elem.color} dir={elem.dir} icon={ elem.icon} name={ elem.name }  ></DotIndex>
                            )
                        } )
                        } 
                        </div>


            </div>
        </div>
        </>
    )
}